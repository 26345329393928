<template>
  <div>
    <!--          option编辑-->
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :title="$fanyi('オプション')"
        :visible.sync="$parent.newShowGoodsOptionDialogVisible"
        @opened="$forceUpdate()"
        center
        width="1375px">
      <div class="optionSelectContainer">
        <div class="optionSelectHeaderContainer">
          <div class="optionSelectListContainer">
            <div class="optionArrContainer">
              <div v-for="(optionItem,optionIndex) in list" :key="optionIndex"
                   class="optionArrItemContainer">
                <div :class="optionItem.checked==true?'activeOptionArrItemNameContainer':'optionArrItemNameContainer'"
                     @click="updateOptionNum(optionItem)">
                  <div class="optionArrItemNameLeftContainer">
                    <div>{{ optionIndex + 1 }}</div>
                    <div>{{ optionItem.name_translate }}</div>
                  </div>
                  <div v-show="optionItem.checked==true" :style="optionItem.checked==true?'border:1px solid #B4272B':''"
                       class="checkedIcon">
                    <img alt="" src="../../../../../../assets/optionCheckedIcon.png">
                  </div>
                  <div v-show="optionItem.checked==false" class="checkedIcon"></div>
                </div>
                <div class="optionNumContainer">
                  <div v-show="optionItem.checked==true" class="fontSize14 color333" style="margin-right:10px">数量
                  </div>
                  <el-input v-show="optionItem.checked==true" v-model="optionItem.num" size="small" style="width: 80px;"
                            @input="updateOptionTotalPrice(optionItem)"
                            @wheel.native.prevent="stopScroll($event)"
                            @keydown.native="newChannelInputLimit"/>
                </div>
              </div>
            </div>
          </div>
          <div v-if="optionInfo.price!=''" class="optionDetailContainer">
            <el-carousel
                :arrow="carouselList.length > 1 ? 'always' : 'never'"
                :autoplay="false"
                height="252px"
                indicator-position="none">
              <el-carousel-item v-for="(item, index) in carouselList" :key="index">
                <img v-show="item.type==='img'" :src="item.url" alt="" class="optionImage cursorPointer"
                     @click="imgFullscreen('.optionImage')">
                <div v-show="item.type!='img'"
                     class="edy-texteditor-container video embed-container edy-positionable-container-maxwidth"
                     contenteditable="false"
                     style="max-width: 100%;overflow: hidden;position: relative;width: auto; "
                     unselectable="on">
                  <div class="edy-padding-resizer-wrapper" style="padding-bottom: 56.25%">
                    <iframe id="ifrId" :src="item.url" allowfullscreen="allowfullscreen" frameborder="0" style="
                                              position: absolute;
                                              display: block;
                                              width: 100%;
                                              height: 100%;
                                            "></iframe>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
            <div class="optionName" style="margin-top: 24px;">{{ optionInfo.name_translate }}</div>
            <div class="optionLabel flexAndCenter"><span>{{ $fanyi('收费标准') }}：</span>
              <div class="optionPrice">{{ optionInfo.price }}{{ optionInfo.unit }}/点</div>
            </div>
            <div class="optionValue" v-html="optionInfo.desc"></div>
          </div>
        </div>
        <div class="optionSelectFooterContainer">
          <div>
            <div class="color999">※ オプションを選択してから数量が自動的に記入します。</div>
            <div class="color999">
              Rakumartの各サービスのオプション料金を確認するためには、
              <router-link :to="{ path: '/option'}"
                           class="colorB4272B cursorPointer"
                           target="_blank">
                オプション費用
              </router-link>
              をクリックしてください。
            </div>
          </div>
          <div class="optionTotalPrice">合計（元）：<span>{{ Number(newOptionPrice).toFixed(2) }}</span></div>
          <div class="optionOperationBtnContainer">
            <el-button @click="resetGoodsOption">{{ $fanyi("重置") }}</el-button>
            <el-button type="primary" @click="saveGoodsOption">{{ $fanyi("保存") }}</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  props: {
    list: {//option集合
      type: Array
    },
    orderList: {//订单数据
      type: Array
    },
    itemIndex: {//订单id
      type: Number
    },
    option: {//默认option集合
      type: Array
    },
    optionPrice: {//option价格
      type: Number
    },
    timer: {//刷新子组件
      type: String
    },
    orderStatus: {//订单状态
      type: String
    }
  },
  data() {
    return {
      newOptionPrice: this.optionPrice,
      optionInfo: {
        desc: '',
        price: '',
        unit: '',
        image: '',
      },
      carouselList: []
    }
  },
  methods: {
    //重置商品的附加服务
    resetGoodsOption() {
      this.newOptionPrice = 0;
      this.list.forEach((item) => {
        item.checked = false;
        item.num = 0;
        item.total = 0;
      })
    },
    //保存商品的附加服务
    saveGoodsOption() {
      this.$parent.newShowGoodsOptionDialogVisible = false;
      this.newOptionPrice = 0;
      this.$emit("saveGoodsOption", this.list);
    },
    //防止输入框滚动增值
    stopScroll(evt) {
      evt = evt || window.event;
      if (evt.preventDefault) {
        // Firefox
        evt.preventDefault();
        evt.stopPropagation();
      } else {
        // IE
        evt.cancelBubble = true;
        evt.returnValue = false;
      }
      return false;
    },
    imgFullscreen(val) {
      const el = document.querySelector(val); // 获取需要全屏的元素
      if (!document.fullscreenElement) { // 判断是否已经处于全屏模式
        el.requestFullscreen().catch(err => { // 进入全屏模式
          console.log(`进入全屏模式失败：${err.message}`);
        });
      } else {
        document.exitFullscreen(); // 退出全屏模式
      }
    },
    //输入框校验
    newChannelInputLimit(e) {
      let key = e.key;
      // 不允许输入'e'、'.'、'。'、'-'
      if (key === "e" || key === "." || key === "。" || key == "-" || key == "-" || key == "-") {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    //根据当前订单的番数更新option选中的数量
    updateOptionNum(item) {
      this.carouselList = [];
      this.$forceUpdate();
      item.checked = !item.checked;
      if (item.checked) {
        this.optionInfo = item;
      }
      if (item.video != '') {
        if (item.image != '') {
          this.$nextTick(() => {
            this.carouselList.push({
              url: item.image,
              type: 'img'
            })
          })
        }
        this.$nextTick(() => {
          this.carouselList.push({
            url: item.video,
            type: 'video'
          })
        })
      } else {
        this.$nextTick(() => {
          this.carouselList.push({
            url: item.image != '' ? item.image : require('../../../../../../assets/optionInPreparationImage.jpg'),
            type: 'img'
          })
        })
      }
      console.log(this.carouselList)
      if (item.checked === true) {
        this.orderList.forEach((newListItem) => {
          if (this.itemIndex === newListItem.id) {
            if (this.$route.query.id != undefined) {//提出前预览
              item.num = newListItem.num;
            } else {//临时保存
              item.num = newListItem.is_client_send_data.num;
            }
          }
        })
      } else {
        this.orderList.forEach((newListItem) => {
          if (this.itemIndex === newListItem.id) {
            item.num = 0;
            item.total = 0;
          }
        })
      }
      this.commodityTotal();
    },
    //更新option选中的商品的总价
    updateOptionTotalPrice(val) {
      val.num = this.$fun.priceReplace(val.num);
      this.commodityTotal();
    },
    commodityTotal() {
      this.newOptionPrice = 0;
      this.list.forEach((item) => {
        if (item.price_type !== 1 && item.checked === true) {
          this.orderList.forEach((newListItem) => {
            if (this.itemIndex === newListItem.id) {
              if (item.name !== '配套' && item.name !== '拍照' && item.name !== '单面印刷' && item.name !== '双面印刷') {
                if (this.$route.query.id != undefined) {//提出前预览
                  if (newListItem.num < item.num) {
                    item.num = newListItem.num;
                  }
                } else {//临时保存
                  if (newListItem.is_client_send_data.num < item.num) {
                    item.num = newListItem.is_client_send_data.num;
                  }
                }
              }
              this.newOptionPrice += item.num * Number(item.price);
              item.total = item.num * Number(item.price);
            }
          })
        }
        if (item.price_type === 1 && item.checked === true) {
          this.orderList.forEach((newListItem) => {
            if (this.itemIndex === newListItem.id) {
              if (this.$route.query.id != undefined) {//临时保存
                if (newListItem.num < item.num) {
                  item.num = newListItem.num;
                }
                this.newOptionPrice += (Number(newListItem.price) * item.num) * (item.price / 100);
                item.total = (Number(newListItem.price) * item.num) * (item.price / 100);
              } else {//提出前预览
                if (newListItem.is_client_send_data.num < item.num) {
                  item.num = newListItem.is_client_send_data.num;
                }
                this.newOptionPrice += (Number(newListItem.is_client_send_data.price) * item.num) * (item.price / 100);
                item.total = (Number(newListItem.is_client_send_data.price) * item.num) * (item.price / 100);
              }
            }
          })
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.optionSelectHeaderContainer {
  height: 573px;
  margin-top: 26px;
  display: flex;
  padding-bottom: 24px;

  .optionSelectListContainer {
    width: 772px;
    height: 549px;
    overflow-y: scroll;
    border-right: 1px solid #DFDFDF;

    .optionTypeName {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 18px;
    }

    .optionArrContainer {
      display: flex;
      flex-wrap: wrap;

      .optionArrItemContainer {
        width: 240px;
        margin: 0 12px 18px 0;

        .optionArrItemNameContainer, .activeOptionArrItemNameContainer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 12px 0 10px;
          cursor: pointer;
          background: #F8F8F8;
          height: 42px;
          margin-bottom: 6px;

          .optionArrItemNameLeftContainer {
            display: flex;
            align-items: center;

            div:first-child {
              width: 22px;
              height: 22px;
              min-width: 22px;

              background: #FFFFFF;
              border-radius: 50%;
              border: 1px solid #333333;
              font-size: 12px;
              color: #333333;
              text-align: center;
              line-height: 19px;
              margin-right: 5px;
            }

            div:last-child {
              font-size: 14px;
              color: #333333;
              width: 155px;
            }
          }
        }

        .activeOptionArrItemNameContainer {
          background: #FAF2F2 !important;

          .optionArrItemNameLeftContainer {
            div:first-child {
              border: 1px solid #B4272B !important;
            }

            div {
              color: #B4272B !important;
            }
          }
        }

        .optionNumContainer {
          display: flex;
          align-items: center;
          padding-left: 9px;
          height: 32px;
        }

        img {
          width: 17px;
          height: 15px;
        }

        .checkedIcon {
          width: 17px;
          height: 17px;
          border: 1px solid #C0C4CC;
          border-radius: 2px;
        }
      }

      .optionArrItemContainer:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  .optionSelectListContainer::-webkit-scrollbar {
    width: 0;
  }

  .optionDetailContainer {
    margin-left: 36px;
    background: #F8F8F8;
    border-radius: 6px;
    padding: 24px 24px 0;
    width: 498px;
    overflow: scroll;

    /deep/ .el-carousel__arrow {
      background: #000;
      opacity: 0.4;
      font-size: 13px;
    }

    /deep/ .el-carousel__arrow i {
      font-weight: 700;
    }

    /deep/ .el-carousel__arrow:hover {
      opacity: 0.6;
    }

    img {
      width: 100%;
      height: 252px;
      margin-bottom: 24px;
    }

    .optionName {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 29px;
    }

    .optionLabel {
      font-size: 14px;
      color: #666666;
      margin-bottom: 30px;
    }

    .optionPrice {
      color: #B4272B;
      font-size: 18px;
      font-weight: bold;
    }

    .optionValue {
      color: #333333;
    }
  }
}

.optionSelectFooterContainer {
  width: 100%;
  border-top: 1px solid #DFDFDF;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .optionTotalPrice {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #333333;

    span {
      font-size: 24px;
      color: #B4272B;
      font-weight: bold;
    }
  }

  .optionOperationBtnContainer {
    display: flex;
    align-items: center;

    button {
      width: 120px;
      height: 34px;
      border-radius: 8px;
      padding: 0;
      font-size: 14px;
    }

    button:first-child {
      border: 1px solid #B4272B;
      color: #B4272B;
    }
  }
}
</style>