<template>
  <div>
    <div :class="orderStatus!=='obligation'&&orderStatus!=='purchase'?'SpecialNoteVue':'newSpecialNoteVue'">
      <div class="footingPriceContainer">
        <div class="SpecialNoteTitle color333">{{ $fanyi("合计") }}</div>
        <div class="footingPriceFooterContainer">
          <div class="footingPriceFooterChildrenContainer flexAndCenter">
            <div class="fontSize14 color333">{{ $fanyi("商品金額") }}</div>
            <div class="fontSize14 color333">{{ $fun.roundFixed(goodsTotalPrice) }} {{ $fanyi("元") }}</div>
          </div>
          <div class="footingPriceFooterChildrenContainer flexAndCenter">
            <div class="fontSize14 color333 flexAndCenter">
              <div class="fontSize14 color333" style="width: 112px;min-width:112px;margin-right: 7px">
                <div>{{ $fanyi("手数料") }}</div>
              </div>
              <div class="minuteText fontSize14" @click="showGoodsServiceChargeDialogVisible=true">
                {{ $fanyi('详细') }}
              </div>
            </div>
            <div class="fontSize14 color333">{{ $fun.roundFixed(goodsTotalServiceCharge) }} {{ $fanyi("元") }}</div>
          </div>
          <div class="footingPriceFooterChildrenContainer flexAndCenter">
            <div class="fontSize14 color333 flexAndCenter">
              <div class="fontSize14 color333" style="width: 112px;min-width:112px;margin-right: 7px">
                {{ $fanyi("オプション手数料") }}
              </div>
              <div class="minuteText fontSize14" @click="showGoodsOptionDialogVisible=true">
                {{ $fanyi('详细') }}
              </div>
            </div>
            <div class="fontSize14 color333">{{ $fun.roundFixed(optionTotalPrice) }} {{ $fanyi("元") }}</div>
          </div>
          <div class="footingPriceFooterChildrenContainer flexAndCenter"
               v-if="orderStatus==='obligation'||orderStatus==='purchase'">
            <div class="fontSize14 color333">{{ $fanyi("国内送料") }}</div>
            <div class="fontSize14 color333">{{ $fun.roundFixed(freightTotalPrice) }} {{ $fanyi("元") }}</div>
          </div>
          <div class="footingPriceFooterChildrenContainer flexAndCenter"
               v-if="orderStatus==='obligation'||orderStatus==='purchase'">
            <div class="fontSize14 color333 flexAndCenter">
              <div class="fontSize14 color333" style="width: 112px;min-width:112px;margin-right: 7px">
                {{ $fanyi("その他") }}
              </div>
              <div class="minuteText fontSize14" slot="reference" @click="otherExpensesDialogVisible=true">
                {{ $fanyi("详细") }}
              </div>
            </div>
            <div class="fontSize14 color333">{{ $fun.roundFixed(otherPrice) }} {{ $fanyi("元") }}</div>
          </div>
          <div class="footingPriceFooterChildrenContainer flexAndCenter">
            <div class="fontSize14 color333">{{ $fanyi("合計金額") }}</div>
            <div class="fontSize14 color333">{{ $fun.roundFixed(RMBTotalPrice) }} {{
                $fanyi("元")
              }}（{{ $fun.yenNumSegmentation(yenTotalPrice.toFixed(0)) }}円）
            </div>
          </div>
        </div>
      </div>
      <!--    物流方式选择-->
      <div class="selectLogisticsCompanyContainer">
        <div class="SpecialNoteTitle color333">{{ $fanyi("国际运输公司选择") }}</div>
        <el-select v-model="selectValue" style="width: 200px;"
                   :disabled="orderStatus==='purchase'||orderStatus==='offer'||(orderStatus==='obligation'&&obligationEditUserRemark===false)"
                   size="small" @change="getLogisticsTransportationTips">
          <el-option
              v-for="item in option"
              :key="item.id"
              :disabled="item.usable==false"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
        <div class="selectLogisticsCompanyExplain">{{ logisticsTransportationTips }}</div>
        <div v-if="$parent.datas.status>=30" class="fontSize14 fontWeightBold" style="margin-top: 28px">
          予想费用：{{ $parent.datas.order_amount.predict_logistics_price_rmb }}元
        </div>
      </div>
      <!--    附件上传-->
      <div class="addAccessoryContainer">
        <div class="fontSize18 fontWeightBold color333">
          {{
            orderStatus === 'temporary' || obligationEditUserRemark === true ? $fanyi("附件上传") : $fanyi("订单附件")
          }}
        </div>
        <upload
            class="upload-demo"
            :action="uploadUrl"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            v-if="orderStatus==='temporary'||obligationEditUserRemark===true"
            :http-request="httpup"
            :before-upload="beforeAvatarUpload"
            :file-list="fileList">
          <div class="flexAndCenter">
            <img src="../../../../../../assets/5.png" alt="" style="width:16px;margin-right:7px">
            <el-button size="small" type="text" style="color: #50C136;text-decoration: underline;font-size:14px">
              {{ $fanyi("添加附件") }}
            </el-button>
          </div>
        </upload>
        <div v-if="(orderStatus!=='temporary'&&obligationEditUserRemark===false)">
          <div v-for="(item,index) in fileList" :key="index" class="fileListContainer flexAndCenter"
               @click="handlePreview(item)"
               v-show="(orderStatus!=='temporary'&&obligationEditUserRemark===false)||(orderStatus==='temporary'&&$route.query.id==undefined)">
            <img src="../../../../../../assets/6.png" alt="" style="width:12px;">
            <el-popover trigger="hover">
              <div class="fileName fontSize14 color333">{{ item.name }}</div>
              <div slot="reference" class="fileName fontSize14 color333" style="max-width: 132px;">{{ item.name }}</div>
            </el-popover>
            <img src="../../../../../../assets/7.png" style="width:16px;margin-left: 12px"
                 @click="deleteFileList(index)"
                 v-if="obligationEditUserRemark===true">
          </div>
        </div>
      </div>
      <div class="orderFormContainer">
        <div class="SpecialNoteTitle color333">{{ $fanyi("注文書備考欄") }}</div>
        <el-input
            type="textarea"
            :placeholder="$fanyi('*对该订单有特殊要求的，可以在这边备注备注后会同步显示给业务员')"
            resize="none"
            v-if="(orderStatus==='temporary'&&$route.query.order_sn==undefined&&$route.query.id!==undefined)||(orderStatus==='temporary'&&$route.query.order_sn!==undefined)"
            @input="updateClientRemarkTextarea"
            v-model="textarea2">
        </el-input>
        <el-input
            type="textarea"
            resize="none"
            :disabled="obligationEditUserRemark===false"
            v-else-if="orderStatus==='obligation'"
            @input="updateClientRemarkTextarea"
            v-model="client_remark">
        </el-input>
        <el-input
            type="textarea"
            resize="none"
            v-else-if="orderStatus==='purchase'||orderStatus==='offer'"
            disabled
            v-model="client_remark">
        </el-input>
        <div v-if="orderStatus==='obligation'||orderStatus==='purchase'" style="margin-top: 10px">
          <div class="SpecialNoteTitle color333">{{ $fanyi("业务员订单备注回复") }}</div>
          <el-input
              v-model="businessRemark"
              disabled
              resize="none"
              type="textarea">
          </el-input>
        </div>
      </div>
      <div v-if="$parent.datas.status!= undefined && $parent.datas.status!= 10" class="orderFormContainer">
        <div class="SpecialNoteTitle color333">{{ $fanyi("订单跟进注意事项") }}</div>
        <el-input
            type="textarea"
            resize="none"
            v-model="$store.state.follow_remark"
            @blur="submitFollowRemark"
            @input="updateFollowRemarkTextarea">
        </el-input>
      </div>
    </div>
    <div class="goodsOptionListContainer">
      <!--    option费用明细-->
      <el-dialog
          :title="$fanyi('オプション詳細')"
          :visible.sync="showGoodsOptionDialogVisible"
          width="440px"
          center>
        <div class="optionHead">
          <ul class="flexAndCenter color333 fontSize14">
            <li class="optionSelect" style="min-width: 200px">{{ $fanyi("内容") }}</li>
            <li class="num">{{ $fanyi("数量") }}</li>
            <li class="estimatedCost">{{ $fanyi("小计") }}（元）</li>
          </ul>
        </div>
        <!-- 单个商品 -->
        <div class="optionFooter">
          <ul v-for="(listItem, listIndex) in selectOptionList" :key="listIndex" class="flexAndCenter color333"
              style="background: transparent">
            <li class="optionSelect" style="text-align: left;min-width: 200px;line-height: normal!important;">{{
                listIndex + 1
              }}）{{
                listItem.price_type != 1 ? listItem.name_translate : `${listItem.name_translate}(${Number(listItem.price)}%)`
              }}
            </li>
            <li class="num">{{ listItem.num }}</li>
            <li class="estimatedCost">{{ (listItem.total).toFixed(2) }}</li>
          </ul>
        </div>
      </el-dialog>
      <!--    单番手续费明细-->
      <el-dialog
          :title="$fanyi('手续费')"
          :visible.sync="showGoodsServiceChargeDialogVisible"
          center
          width="440px">
        <div class="optionHead">
          <ul class="flexAndCenter color333 fontSize14">
            <li class="optionSelect newOptionSelect">No</li>
            <li class="num" style="max-width:150px;width:150px">{{ $fanyi("收费比例") }}</li>
            <li class="estimatedCost" style="width:150px">{{ $fanyi("手续费") }}(元)</li>
          </ul>
        </div>
        <!-- 单个商品 -->
        <div class="optionFooter goodsServiceContainer">
          <ul v-for="(listItem, listIndex) in goodsTotalServiceChargeDetailsList" :key="listIndex"
              class="flexAndCenter color333"
              style="background: transparent">
            <li class="optionSelect newOptionSelect">{{ listIndex + 1 }}</li>
            <li class="num" style="max-width:150px;width:150px">{{
                listItem.service_rate
              }}%
            </li>
            <li class="estimatedCost" style="min-width:150px">{{ listItem.value.toFixed(2) }}</li>
          </ul>
        </div>
      </el-dialog>
      <!--      其他费用明细展示-->
      <el-dialog
          :title="$fanyi('其他费用明细')"
          :visible.sync="otherExpensesDialogVisible"
          center
          width="380px">
        <div class="otherExpensesHead">
          <ul class="flexAndCenter color333 fontSize14">
            <li class="optionSelect">{{ $fanyi("名义") }}</li>
            <li class="num">{{ $fanyi("费用（元）") }}</li>
          </ul>
        </div>
        <!-- 单个商品 -->
        <div class="otherExpensesFooter">
          <ul class="flexAndCenter color333" style="background: transparent">
            <li class="optionSelect">
              {{ otherPriceRemark }}
            </li>
            <li class="num">
              {{ otherPrice }}
            </li>
          </ul>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import upload from "@/components/upload";
import {mapState} from "vuex";

export default {
  components: {
    upload
  },
  props: {
    goodsTotalPrice: {//商品金額
      type: Number
    },
    goodsTotalServiceCharge: {//手续费金额
      type: Number
    },
    optionTotalPrice: {//option金額
      type: Number
    },
    RMBTotalPrice: {//人民币总金额
      type: Number
    },
    yenTotalPrice: {//日元总金额
      type: Number
    },
    goodsTotalServiceChargeDetailsList: {//手续费明细
      type: Array
    },
    orderStatus: {//订单状态
      type: String,
      default: '',
    },
    obligationEditUserRemark: {//待支付订单是否编辑
      type: Boolean,
      default: false,
    },
    selectOptionList: {//用户选择的option汇总列表
      type: Array,
      default: [],
    },
    otherPrice: {//其他费用
      type: Number
    },
    otherPriceRemark: {//其他费用备注
      type: String,
      default: ''
    },
    freightTotalPrice: {//物流费用
      type: Number,
      default: 0,
    },
    clientRemark: {//用户的备注
      type: String,
      default: '',
    },
    clientRemarkDisabled: {//用户的备注
      type: Boolean,
      default: false,
    },
    businessRemark: {//业务备注
      type: String,
      default: '',
    }
  },
  computed: {
    ...mapState({
      option: "logisticsTransportation",
    }) /* 推荐 */,
    client_remark: {
      get() {
        return this.$store.state.client_remark;
      },
      set() {
      }
    },
    follow_remark: {
      get() {
        return this.$store.state.follow_remark;
      },
      set() {
      }
    }
  },
  data() {
    return {
      selectValue: '',
      fileList: [],
      fileName: '',
      showGoodsOptionDialogVisible: false,
      otherExpensesDialogVisible: false,
      showGoodsServiceChargeDialogVisible: false,
      showServiceChargeDialogVisible: false,
      textarea1: '',
      textarea2: '',
      logisticsTransportationTips: '',
    };
  },
  mounted() {
    this.$forceUpdate();
    this.textarea2 = this.clientRemark;
    this.$forceUpdate();
  },
  methods: {
    //上传文件删除
    handleRemove(val) {
      const index = this.fileList.findIndex(item => item.file === val.file);
      this.fileList.splice(index, 1)
    },
    //上传文件预览
    handlePreview(file) {
      window.open(file.file)
    },
    // 图片上传事件
    async httpup(file) {
      this.fileName = file.file.name;
      const imgOBJ = await this.$fun.startUpload('multipartUpload', file.file, this.MastermaphandleUploadProgress)
      if (imgOBJ.res.requestUrls[0] && imgOBJ.res.status == 200) {
        this.uploadPercentage = 0
        this.handleSuccess(imgOBJ.res.requestUrls[0])
      }
    },
    MastermaphandleUploadProgress(rawFile, num) {
      // 使用 Number.isFinite 来检查 num 是否是一个有限数字
      if (Number.isFinite(num)) {
        this.uploadPercentage = num;
      } else {
        this.uploadPercentage = 50
      }
    },
    //文件上传成功
    handleSuccess(file) {
      this.loading.close();
      this.fileList.push({name: this.fileName, file: file});
    },
    //更新用户输入订单整体备注
    updateClientRemarkTextarea(e) {
      this.$store.commit("updateClient_remark", {text: e});
    },
    //更新用户输入跟进注意事项
    updateFollowRemarkTextarea(e) {
      this.$store.commit("updateFollow_remark", {text: e});
    },
    //提交用户的跟进注意事项
    submitFollowRemark() {
      if (this.$store.state.follow_remark != '' && this.$route.query.order_sn != undefined) {
        this.$api.followRemarkEdit({
          order_sn: this.$route.query.order_sn,
          follow_remark: this.$store.state.follow_remark
        }).then(() => {

        });
      }
    },
    //获取物流tips
    getLogisticsTransportationTips(e) {
      this.option.forEach((item) => {
        if (item.id === e) {
          this.logisticsTransportationTips = item.tips
        }
      })
    },
    //删除文件
    deleteFileList(index) {
      this.fileList.splice(index, 1);
    },
    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 1024;
      if (!isLt5M) {
        this.$message.error('文件大小超出限制');
        return isLt5M
      }
      this.loading = this.$loading({
        lock: true,
        text: this.$fanyi("文件上传中"),
        spinner: "el-icon-loading fontSize50",
        background: "rgba(255, 255, 255, 0.7)",
      });
    },
    handleClose(done) {
      this.$confirm(`${this.$fanyi("确认关闭")}？`)
      .then(_ => {
        done();
      })
      .catch(_ => {
      });
    },
  },
};
</script>
<style lang='scss' scoped>
@import "../../../../../../css/mixin";
@import "../css/orderDetails.scss";

.newSpecialNoteVue {
  min-height: 312px !important;
}

.fileListContainer {
  margin-bottom: 21px;

  .fileName {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 3px;
    cursor: pointer;
  }
}

.optionHead {
  ul {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: #F0F0F0;
    height: 46px;

    li {
      text-align: center;
      line-height: 46px;
      font-size: 14px;
      color: #333;
    }

    .optionSelect {
      width: 200px;
      min-width: 200px;
      padding-left: 26px;
      box-sizing: border-box;
    }

    .newOptionSelect {
      min-width: 80px !important;
      width: 80px !important;
      max-width: 80px !important;
      padding-left: 0 !important
    }

    .num {
      width: 80px;
      max-width: 80px;
    }

    .estimatedCost {
      width: 100px;
      min-width: 100px;
    }
  }
}

.goodsServiceContainer {
  height: 600px;
  overflow: scroll;
}

.goodsServiceContainer::-webkit-scrollbar {
  display: none !important;
}

.optionFooter {
  max-height: 500px;
  overflow-y: scroll;

  ul {
    height: 50px;
    border-bottom: 1px solid #DFDFDF;

    li {
      text-align: center;
      line-height: 50px;
      font-size: 14px;
      color: #333;
    }

    .optionSelect {
      width: 200px;
      padding-left: 26px;
      box-sizing: border-box;
      min-width: 200px;
    }

    .newOptionSelect {
      min-width: 80px !important;
      width: 80px !important;
      max-width: 80px !important;
      padding-left: 0 !important;
      text-align: center !important;
    }

    .num {
      width: 80px;
      max-width: 80px;
      color: #B4272B;
      text-decoration: underline;
    }

    .estimatedCost {
      width: 100px;
      min-width: 100px;
    }
  }
}

.optionFooter::-webkit-scrollbar {
  width: 0;
}

.otherExpensesHead {
  ul {
    height: 46px;
    background: #F0F0F0;
    margin-top: 40px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    li {
      text-align: center;
      line-height: 46px;
      font-size: 14px;
      color: #333;
    }

    li:first-child {
      width: 200px;
    }

    li:last-child {
      width: 120px;
    }
  }
}

.otherExpensesFooter {
  ul {
    height: 50px;

    li {
      text-align: center;
      font-size: 14px;
      color: #333;
    }

    li:first-child {
      width: 200px;
      min-width: 200px;
    }

    li:last-child {
      width: 120px;
      min-width: 120px;
    }
  }
}

.otherTableTitle {
  .newLeft, .newCenter {
    width: 150px;
    text-align: center;
  }
}

.SpecialNoteVue, .newSpecialNoteVue {
  background: #FFF8EE;
  border-radius: 10px;
  min-height: 251px;
  box-sizing: border-box;
  padding: 30px 25px;
  display: flex;
  justify-content: space-between;

  .SpecialNoteTitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 28px;
  }

  .footingPriceContainer {
    width: 400px;

    .footingPriceFooterChildrenContainer {
      margin-bottom: 19px;

      div:first-child {
        width: 184px;

        .minuteText {
          color: #B4272B;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  /deep/ textarea::-webkit-input-placeholder {
    -webkit-text-fill-color: #C6C6C6;
    font-size: 14px;
  }

  /deep/ textarea::-ms-input-placeholder {
    -webkit-text-fill-color: #C6C6C6;
    font-size: 14px;
  }

  .selectLogisticsCompanyContainer {
    .selectLogisticsCompanyExplain {
      width: 205px;
      font-size: 12px;
      color: #B4272B;
      margin-top: 11px;
    }

    .prospectPrice {
      margin: 28px 0 10px;
      font-weight: bold;
      font-size: 14px;
    }

    .prospectPriceExplain {
      color: #B4272B;
      text-decoration: underline;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .addAccessoryContainer {
    width: 200px;

    /deep/ .el-upload-list__item-name {
      width: 132px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    /deep/ .el-progress__text {
      display: none !important;
    }
  }

  .orderFormContainer, .orderRemarkContainer, .businessRemarkContainer, .orderFollowRemarkContainer {
    .el-textarea {
      width: 240px;
    }

    /deep/ .el-textarea__inner {
      min-height: 153px !important;
    }
  }

  .orderFollowRemarkContainer {
    .el-textarea {
      width: 160px;
    }
  }

  .orderRemarkContainer {
    .el-textarea {
      width: 110px;
    }
  }

  .businessRemarkContainer {
    .el-textarea {
      width: 210px;
    }
  }
}
</style>
